import React from 'react'

import classNames from 'classnames'

import styles from './styles.module.scss'

type ButtonProps = {
    children: React.ReactNode
    onClick?: () => void
    className?: string
}

const Button = ({ children, onClick, className }: ButtonProps) => {
    return (
        <button
            className={classNames(styles.button, className)}
            onClick={onClick}
        >
            {children}
        </button>
    )
}

export default Button
