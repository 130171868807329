import React from 'react'

import classnames from 'classnames'

import Button from '../../../components/Button'

import LabelValuePair from './LabelValuePair'
import styles from './styles.module.scss'

import type { NominationSummary, Navigation } from './viewModel'

type Props = {
  summary: NominationSummary
  navigation: Navigation
}

const NominationSummaryBlock = ({ summary, navigation }: Props) => {
  return (
    <div className={styles.container}>
      <div className={classnames([styles.section, styles.nominationSummary])}>
        <div className={styles.info}>
          <h2>{summary.title}</h2>
          <LabelValuePair label="Nomination time" value={summary.recordTime} />
        </div>
        <div className={styles.actions}>
          <Button className={styles.button} onClick={navigation.newNominationVersion}>
            New version
          </Button>
        </div>
      </div>
    </div>
  )
}

export default NominationSummaryBlock
