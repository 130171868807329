import { lazy, Suspense, PropsWithChildren } from 'react'

import { Toaster } from 'react-hot-toast'
import { parse } from 'regexparam'
import { Provider } from 'urql'
import { Switch, Route, Redirect, Router } from 'wouter'
import makeCachedMatcher from 'wouter/matcher'

import { isProductionEnv } from './constants/env-vars'
import { createUrqlClient } from './graphql/client'
import { useAuthorizationContext } from './Pages/Account/AuthorizationContext'
import { LoginPage } from './Pages/Account/LoginPage'
import { NewPasswordRequiredPage } from './Pages/Account/NewPasswordRequiredPage'
import { ResetPasswordPage } from './Pages/Account/ResetPasswordPage'
import { SetPasswordPage } from './Pages/Account/SetPasswordPage'
import { BargesPage } from './Pages/Barges'
import { Docs } from './Pages/Docs'
import NominationDetailsPage from './Pages/Nominations/Details'
import NominationFormPage from './Pages/Nominations/Form'
import { GoalsDiff } from './Pages/Nominations/GoalsDiff/GoalsDiff'
import NominationListPage from './Pages/Nominations/List'
import { NewNominationPage } from './Pages/Nominations/NewNomination/NewNominationPage'
import { SavedNominationPage } from './Pages/Nominations/SavedNomination'
import { PageNotFound } from './Pages/PageNotFound'
import BargeSelectionBridge from './providers/BargeSelectionBridge'
import FeatureFlagProvider from './providers/FeatureFlagProvider'
import { LatestNominationProvider } from './providers/LatestNominationProvider'
import NominationRequestBridgeProvider from './providers/NominationRequestBridge'
import { SavedNominationsProvider, useSavedNominationsContext } from './providers/SavedNominationsProvider'
import { SettingsProvider } from './providers/SettingsProvider'
import { RightBottomRibbon } from './ui/Ribbon/Ribbon'
import { LoadingSpinner } from './ui/Spinner/Spinner'

const GraphiqlPage = lazy(() =>
  isProductionEnv ? Promise.reject(new Error('Not availlable')) : import('./Pages/Graphiql/GraphiqlPages')
)
const PageContainer = ({ children }: PropsWithChildren<{}>) => (
  <FeatureFlagProvider>
    <SettingsProvider>
      <NominationRequestBridgeProvider>
        <BargeSelectionBridge>
          <SavedNominationsProvider>
            <LatestNominationProvider>
              <>{children}</>
              {isProductionEnv ? null : <RightBottomRibbon />}
              <Toaster toastOptions={{ duration: Infinity }} />
            </LatestNominationProvider>
          </SavedNominationsProvider>
        </BargeSelectionBridge>
      </NominationRequestBridgeProvider>
    </SettingsProvider>
  </FeatureFlagProvider>
)

function FirstSavedNominationRedirect() {
  const { nominations } = useSavedNominationsContext()

  return <Redirect to={nominations.length ? `/nominations/${nominations[0].uuid}/latest` : '/nominations/view'} />
}

const matcher = makeCachedMatcher((path: string) => {
  const { keys, pattern } = parse(path)

  return { keys: keys.map(name => ({ name })), regexp: pattern }
})

export function AppRoutes() {
  const { isLoading, isAuthenticated, getAccessToken } = useAuthorizationContext()

  if (isLoading) {
    return <LoadingSpinner isFullScreen />
  }

  return (
    <Router matcher={matcher}>
      <Switch>
        <Route path="/login" component={LoginPage} />
        <Route path="/reset-password" component={ResetPasswordPage} />
        <Route path="/set-password" component={SetPasswordPage} />
        <Route path="/forced-password-reset" component={NewPasswordRequiredPage} />
        {isAuthenticated && !isProductionEnv && (
          <Route path="/graphiql">
            <Suspense>
              <GraphiqlPage getToken={getAccessToken} />
            </Suspense>
          </Route>
        )}
        <Route>
          {isAuthenticated ? (
            <Provider value={createUrqlClient(getAccessToken)}>
              <AuthenticatedRoutes />
            </Provider>
          ) : (
            <Redirect to="/login" />
          )}
        </Route>
      </Switch>
    </Router>
  )
}

const AuthenticatedRoutes = () => (
  <PageContainer>
    <Switch>
      <Route path="/docs/*" component={Docs} />
      <Route path="/docs" component={Docs} />
      {isProductionEnv ? null : (
        <>
          <Route path="/nominations" component={NominationListPage} />
          <Route path="/nominations/new" component={() => <NominationFormPage id={null} />} />
          <Route
            path="/nomination/:id"
            component={({ params: { id } }) => <NominationDetailsPage id={id} version={null} />}
          />
          <Route
            path="/nomination/:id/version/new"
            component={({ params: { id } }) => <NominationFormPage id={id} />}
          />
          <Route
            path="/nomination/:id/version/:version"
            component={({ params: { id, version } }) => <NominationDetailsPage id={id} version={version} />}
          />
        </>
      )}
      <Route path="/nominations/create">
        <NewNominationPage mode="create" />
      </Route>
      <Route path="/nominations/edit">
        <NewNominationPage mode="edit" />
      </Route>
      <Route path="/nominations/view">
        <NewNominationPage mode="view" />
      </Route>
      <Route<{ uuid: string; version: string }>
        path="/nominations/:uuid/:version"
        component={({ params: { uuid, version } }) => <SavedNominationPage uuid={uuid} version={version} />}
      />
      {isProductionEnv ? null : <Route path="/goals-diff" component={GoalsDiff} />}
      <Route path="/barges" component={BargesPage} />
      <Route path="/">
        <FirstSavedNominationRedirect />
      </Route>
      <Route component={PageNotFound} />
    </Switch>
  </PageContainer>
)
