import React from 'react'

import { SvgIconComponent } from '@mui/icons-material'
import { Tooltip } from '@mui/material'

type TowActionButtonProps = {
    label: string;
    getData: () => void; 
    Icon: SvgIconComponent
    className?: string;
}

const TowActionButton: React.FC<TowActionButtonProps> = ({ label, getData, Icon, className }) => (
    <Tooltip title={label} placement="top">
        <button className={className} type="button" onClick={getData}>
            <span className="sr-only">{label}</span>
            <Icon />
        </button>
    </Tooltip>
)

export default TowActionButton
