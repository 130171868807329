import { useEffect, useMemo, useState } from 'react'

import * as R from 'ramda'
import { useLocation } from 'wouter'

import { getDataByColumns } from '../../../components/Table/NominatedBargesTable'
import { ELLIPSIS } from '../../../constants/constants'
import { equals, showRiverLocation } from '../../../Domain/River'
import {
  type GoalId,
  HubLike,
  HubLikeId,
  HullType,
  type LaneId,
  LoadStatus,
  OverviewNominationVersionType,
  type RiverLocationLite,
} from '../../../generated/graphql'
import {
  formatBoat,
  formatBoolean,
  formatHours,
  formatHubLike,
  formatOptional,
  formatPercents,
} from '../../../lib/formatters'
import {
  type ExternalNominationVersionData,
  isExternalVersion,
  isNativeVersion,
  type NativeNominationVersionData,
  type NominatedBarge,
  type NominationData,
  type NominationVersionData,
  type StopsWithMetrics,
  type TbnBarge,
} from '../../../models/models'
import useNominationModel from '../../../models/useNominationModel'
import { useNominationRequestBridge } from '../../../providers/NominationRequestBridge'
import { useSettingsContext } from '../../../providers/SettingsProvider'
import { toString } from '../../../utils/date'
import { handleCSVDownload } from '../../../utils/downloadFile'

import type { NominatableBoat } from '../../../Domain/Nomination'

export type VersionLink = {
  readonly id: string
  readonly name: string
  readonly recordTime: string
  readonly path: string
  readonly type: OverviewNominationVersionType
  readonly active: boolean
}

export type Navigation = {
  readonly links: VersionLink[]
  newNominationVersion: () => void
  redirectToLatest: () => void
}

export type NominationSummary = {
  readonly title: string
  readonly recordTime: string
}

type NominatedBargeStatistics = {
  readonly quantity: number
  readonly empty: number
  readonly emptyRakes: number
  readonly emptyBoxes: number
  readonly loaded: number
  readonly loadedRakes: number
  readonly loadedBoxes: number
  readonly rakes: number
  readonly boxes: number
}

type TbnStatisticsEntry = { [LoadStatus.Loaded]: number;[LoadStatus.Empty]: number; quantity: number; title: string }
type TbnBargeStatistics = {
  readonly quantity: number
  readonly entries: TbnStatisticsEntry[]
}

type HotBargeStatistics = {
  readonly quantity: number
  readonly [HullType.Rake]: number
  readonly [HullType.Box]: number
}

export type VersionSummary = {
  readonly recordTime: string
  readonly lane: string
  readonly origin: string
  readonly destination: string
  readonly expectedDepartureDate: string
  readonly operationalGoal: string
  readonly transitTime: string
  readonly dwellTime: string
  readonly vessel: string
  readonly hasTurnboat: string
  readonly totalStops: number
  readonly totalDestinations: number
  readonly totalBargeMiles: number
  readonly towScore: string
  readonly nominatedBargeStatistics: NominatedBargeStatistics
  readonly tbnBargeStatistics: TbnBargeStatistics
  readonly hotBargeStatistics: HotBargeStatistics
  readonly type: OverviewNominationVersionType
}

export type Stop = {
  readonly code: string
  readonly mileagePoint: number
  readonly dropOffs: string[]
  readonly pickUps: string[]
  readonly inTowBarges: string[]
  readonly travelMinutesToNextStop: number | null
  readonly dwellMinutes: number | null
  readonly distanceToNextStop: number
  readonly bargeMiles: number
}

export type JourneyData = {
  readonly stops: Stop[]
  readonly boat: string
  readonly maximumBargesInTow: number
  readonly selectedStop: RiverLocationLite | null
  stopSelectionHandler: (location: RiverLocationLite | null) => void
}

export type SelectedExternalVersion = {
  readonly summary: VersionSummary
  readonly journey: JourneyData
  readonly nominatedBarges: NominatedBarge[]
  bargeNamesCallback: () => string
  downloadNominationCsvCallback: () => void
}

export type SelectedNativeVersion = {
  readonly summary: VersionSummary
  readonly journey: JourneyData
  readonly nominatedBarges: NominatedBarge[]
  bargeNamesCallback: () => string
  downloadNominationCsvCallback: () => void
}

export type SelectedVersion = SelectedNativeVersion | SelectedExternalVersion

export type FetchingNominationDetailsViewModel = {
  readonly fetching: true
}

export type FetchedNominationDetailsViewModel = {
  readonly fetching: false
  readonly navigation: Navigation
  readonly nominationSummary: NominationSummary
  readonly selectedVersion: SelectedVersion | null
}

type NominationDetailsViewModel = FetchingNominationDetailsViewModel | FetchedNominationDetailsViewModel

export const isFetching = (ndvm: NominationDetailsViewModel): ndvm is FetchingNominationDetailsViewModel =>
  ndvm.fetching
export const isFetched = (ndvm: NominationDetailsViewModel): ndvm is FetchedNominationDetailsViewModel => !ndvm.fetching

const UNKNOWN_BOAT = 'Unknown'

const buildNominatedBargeStatistics = (barges: NominatedBarge[]): NominatedBargeStatistics => {
  const initialBargeStatistics: NominatedBargeStatistics = {
    quantity: 0,
    empty: 0,
    emptyRakes: 0,
    emptyBoxes: 0,
    loaded: 0,
    loadedRakes: 0,
    loadedBoxes: 0,
    rakes: 0,
    boxes: 0,
  }

  const asKey = (loadStatus: LoadStatus, hullType: HullType): string => `${loadStatus}:${hullType}`

  const lensSets: Record<string, R.Lens<NominatedBargeStatistics, number>[]> = {
    [asKey(LoadStatus.Empty, HullType.Rake)]: [R.lensProp('empty'), R.lensProp('rakes'), R.lensProp('emptyRakes')],
    [asKey(LoadStatus.Empty, HullType.Box)]: [R.lensProp('empty'), R.lensProp('boxes'), R.lensProp('emptyBoxes')],
    [asKey(LoadStatus.Loaded, HullType.Rake)]: [R.lensProp('loaded'), R.lensProp('rakes'), R.lensProp('loadedRakes')],
    [asKey(LoadStatus.Loaded, HullType.Box)]: [R.lensProp('loaded'), R.lensProp('boxes'), R.lensProp('loadedBoxes')],
  }
  const quantityLens: R.Lens<NominatedBargeStatistics, number> = R.lensProp('quantity')

  const reducer = (acc: NominatedBargeStatistics, barge: NominatedBarge) => {
    const lensesForCurrentBarge: R.Lens<NominatedBargeStatistics, number>[] =
      lensSets[asKey(barge.loadStatus, barge.hullType ?? HullType.Box)]

    if (!lensesForCurrentBarge) return acc

    const lenses: R.Lens<NominatedBargeStatistics, number>[] = R.append(
      quantityLens,
      lensSets[asKey(barge.loadStatus, barge.hullType ?? HullType.Box)]
    )
    const modifiers: ((value: NominatedBargeStatistics) => NominatedBargeStatistics)[] = R.map(
      lens => R.over(lens, R.inc),
      lenses
    )

    // @ts-ignore
    return R.pipe(...modifiers)(acc)
  }

  return R.reduce(reducer, initialBargeStatistics, barges)
}

type TbnStatisticsAggregator = Record<string, TbnStatisticsEntry>

const buildTbnStatistics = (tbnBarges: TbnBarge[]): TbnBargeStatistics => {
  const groupTitle = (pickup: string, dropOff: string) => `${pickup} → ${dropOff}`
  const reducer = (acc: TbnStatisticsAggregator, barge: TbnBarge): TbnStatisticsAggregator => {
    const pickupLabel = showRiverLocation(barge.pickupFacility)
    const dropOffLabel = showRiverLocation(barge.dropOffFacility)
    const key = `${pickupLabel}-${dropOffLabel}`

    const currentEntry = acc[key] ?? {
      [LoadStatus.Loaded]: 0,
      [LoadStatus.Empty]: 0,
      quantity: 0,
      title: groupTitle(pickupLabel, dropOffLabel),
    }
    const modifiedEntry = R.pipe(
      R.assoc(barge.expectedLoadStatus, R.inc(currentEntry[barge.expectedLoadStatus])),
      R.assoc('quantity', R.inc(currentEntry.quantity))
    )(currentEntry)

    return R.assoc(key, modifiedEntry, acc)
  }

  const entries: TbnStatisticsEntry[] = R.pipe(R.reduce(reducer, {}), R.values)(tbnBarges)
  const quantity = R.pipe(
    R.map((e: TbnStatisticsEntry) => e.quantity),
    R.sum
  )(entries)

  return {
    quantity,
    entries,
  }
}

const buildHotStatistics = (barges: NominatedBarge[]): HotBargeStatistics => {
  const hotBarges = R.filter(b => b.isHot, barges)
  const quantity = hotBarges.length
  const rakes = R.filter(b => b.hullType === HullType.Rake, hotBarges).length
  const boxes = R.filter(b => b.hullType === HullType.Box, hotBarges).length

  return {
    quantity,
    [HullType.Rake]: rakes,
    [HullType.Box]: boxes,
  }
}

const computeTransitTime = (stopsWithMetrics: StopsWithMetrics[]): number => {
  return (
    R.pipe(
      R.map((s: StopsWithMetrics) => s.travelMinutesToNextStop ?? 0),
      R.sum
    )(stopsWithMetrics) / 60
  )
}

const computeDwellTime = (stopsWithMetrics: StopsWithMetrics[]): number => {
  return (
    R.pipe(
      R.map((s: StopsWithMetrics) => s.dwellMinutes ?? 0),
      R.sum
    )(stopsWithMetrics) / 60
  )
}

const computeTotalDestinations = (barges: NominatedBarge[]): number => {
  const destinations = R.map(b => (b.destination ? showRiverLocation(b.destination) : null), barges)
  return new Set(destinations).size
}

type JourneyDataAccumulator = {
  previous: Stop | null
  stops: Stop[]
}
const buildJourney = (
  stopsWithMetrics: StopsWithMetrics[],
  boat: string,
  nominatedBarges: NominatedBarge[],
  selectedStop: RiverLocationLite | null,
  setSelectedStop: (stop: RiverLocationLite | null) => void): JourneyData => {
  const initial: JourneyDataAccumulator = { previous: null, stops: [] }
  const stopsAccumulator: JourneyDataAccumulator = R.reduce(
    (acc: JourneyDataAccumulator, s: StopsWithMetrics): JourneyDataAccumulator => {
      const dropOffs = R.map(b => b.id, s.bargesToDrop)
      const pickUps = R.map(b => b.id, s.bargesToPickup)

      const alreadyInTow = acc.previous?.inTowBarges ?? []
      const inTowBarges = R.difference(R.union(alreadyInTow, pickUps), dropOffs)

      const distanceToNextStop = s.distanceToNextStop ?? 0
      const bargeMiles = inTowBarges.length * distanceToNextStop

      const currentStop = {
        code: s.stop.code,
        mileagePoint: s.stop.mileagePoint,
        dropOffs,
        pickUps,
        inTowBarges,
        travelMinutesToNextStop: s.travelMinutesToNextStop,
        dwellMinutes: s.dwellMinutes,
        distanceToNextStop,
        bargeMiles,
      }

      return {
        previous: currentStop,
        stops: [...acc.stops, currentStop],
      }
    },
    initial,
    stopsWithMetrics
  )
  const { stops } = stopsAccumulator

  const maximumBargesInTow = R.reduce((max, stop) => R.max(max, stop.inTowBarges.length), 0, stops)

  return {
    boat,
    stops,
    maximumBargesInTow,
    selectedStop,
    stopSelectionHandler: setSelectedStop,
  }
}

export const getNominatedBargesForStop = (
  journey: JourneyData,
  nominatedBarges: NominatedBarge[],
  selectedStop: RiverLocationLite | null
): NominatedBarge[] => {
  if (!selectedStop) return nominatedBarges

  const selectedJourneyStop = journey.stops.find(stop => equals(stop, selectedStop))
  if (!selectedJourneyStop) return []

  return selectedJourneyStop.inTowBarges.flatMap(bargeId => nominatedBarges.filter(barge => barge.id === bargeId))
}

const buildNativeVersionSummary = (
  selectedVersion: NativeNominationVersionData,
  totalBargeMiles: number,
  lanes: Record<LaneId, string>,
  goals: Record<GoalId, { label: string; description: string }>,
  hubs: Record<HubLikeId, HubLike>,
  boats: NominatableBoat[]
): VersionSummary => {
  const { recordTime, nominationRequest, tow } = selectedVersion
  const { towConfiguration, bargeFilters } = nominationRequest

  const formattedBoatIdentity = formatBoat(towConfiguration.boat, boats)

  return {
    type: selectedVersion.type,
    recordTime: toString(recordTime),
    lane: bargeFilters ? lanes[bargeFilters.lane] : ELLIPSIS,
    origin: bargeFilters ? formatHubLike(bargeFilters.towOrigin, hubs) : ELLIPSIS,
    destination: bargeFilters ? formatHubLike(bargeFilters.towDestination, hubs) : ELLIPSIS,
    expectedDepartureDate: bargeFilters ? formatOptional(bargeFilters.expectedDepartureTime, toString) : ELLIPSIS,
    operationalGoal: towConfiguration ? goals[towConfiguration.goal].label : ELLIPSIS,
    transitTime: formatHours(computeTransitTime(tow.stopsWithMetrics)),
    dwellTime: formatHours(computeDwellTime(tow.stopsWithMetrics)),
    vessel: formattedBoatIdentity,
    hasTurnboat: towConfiguration ? formatBoolean(towConfiguration.hasTurnboat) : ELLIPSIS,
    totalStops: tow.stopsWithMetrics.length,
    totalDestinations: computeTotalDestinations(tow.barges),
    totalBargeMiles,
    towScore: formatOptional(tow.efficiencyMetric, formatPercents),
    nominatedBargeStatistics: buildNominatedBargeStatistics(tow.barges),
    hotBargeStatistics: buildHotStatistics(tow.barges),
    tbnBargeStatistics: buildTbnStatistics(tow.tbnBarges),
  }
}

const buildExternalVersionSummary = (
  selectedVersion: ExternalNominationVersionData,
  totalBargeMiles: number
): VersionSummary => {
  const { recordTime, tow } = selectedVersion

  return {
    type: selectedVersion.type,
    recordTime: toString(recordTime),
    lane: ELLIPSIS,
    origin: ELLIPSIS,
    destination: ELLIPSIS,
    expectedDepartureDate: ELLIPSIS,
    operationalGoal: ELLIPSIS,
    transitTime: formatHours(computeTransitTime(tow.stopsWithMetrics)),
    dwellTime: formatHours(computeDwellTime(tow.stopsWithMetrics)),
    vessel: ELLIPSIS,
    hasTurnboat: ELLIPSIS,
    totalStops: tow.stopsWithMetrics.length,
    totalDestinations: computeTotalDestinations(tow.barges),
    totalBargeMiles,
    towScore: formatOptional(tow.efficiencyMetric, formatPercents),
    nominatedBargeStatistics: buildNominatedBargeStatistics(tow.barges),
    tbnBargeStatistics: buildTbnStatistics(tow.tbnBarges),
    hotBargeStatistics: buildHotStatistics(tow.barges),
  }
}

const totalBargeMilesForJourney = (journey: JourneyData): number => {
  return R.pipe(
    R.map((s: Stop) => s.bargeMiles),
    R.sum
  )(journey.stops)
}

const buildSelectedVersion = (
  selectedVersion: NominationVersionData,
  lanes: Record<LaneId, string>,
  goals: Record<GoalId, { label: string; description: string }>,
  hubs: Record<HubLikeId, HubLike>,
  boats: NominatableBoat[],
  selectedStop: RiverLocationLite | null,
  setSelectedStop: (stop: RiverLocationLite | null) => void,
): SelectedVersion => {
  const bargeNamesCallback = () => R.map(b => b.name, selectedVersion.tow.barges).join(', ')

  const downloadNominationCsvCallback = () => {
    const nominatedBarges = selectedVersion.tow.barges
    const data = getDataByColumns(nominatedBarges)
    handleCSVDownload({ data })
  }

  if (isNativeVersion(selectedVersion)) {
    const { boat } = selectedVersion.nominationRequest.towConfiguration
    const formattedBoatIdentity = formatBoat(boat, boats)
    const journey = buildJourney(
      selectedVersion.tow.stopsWithMetrics,
      formattedBoatIdentity,
      selectedVersion.tow.barges,
      selectedStop,
      setSelectedStop
    )

    const totalBargeMiles = totalBargeMilesForJourney(journey)

    return {
      summary: buildNativeVersionSummary(selectedVersion, totalBargeMiles, lanes, goals, hubs, boats),
      journey,
      nominatedBarges: selectedVersion.tow.barges,
      bargeNamesCallback,
      downloadNominationCsvCallback,
    }
  }
  if (isExternalVersion(selectedVersion)) {
    const journey = buildJourney(
      selectedVersion.tow.stopsWithMetrics,
      UNKNOWN_BOAT,
      selectedVersion.tow.barges,
      selectedStop,
      setSelectedStop
    )

    const totalBargeMiles = totalBargeMilesForJourney(journey)

    return {
      summary: buildExternalVersionSummary(selectedVersion, totalBargeMiles),
      journey,
      nominatedBarges: selectedVersion.tow.barges,
      bargeNamesCallback,
      downloadNominationCsvCallback,
    }
  }
  throw new TypeError('Version is not native nor internal')
}

const buildNavigation = (
  nominationId: string,
  versions: NominationVersionData[],
  latestVersionId: string,
  navigate: (to: string, options?: { replace?: boolean }) => void,
  currentVersionId: string | null,
  setCurrentVersionId: (versionId: string) => void
): Navigation => {
  const versionLinks = versions.map(v => {
    return {
      id: v.id,
      name: v.slug,
      recordTime: toString(v.recordTime),
      path: `/nomination/${nominationId}/version/${v.id}`,
      type: v.type,
      active: v.id === currentVersionId,
    }
  })

  const redirectToLatest = () => {
    setCurrentVersionId(latestVersionId)
    navigate(`/nomination/${nominationId}/version/${latestVersionId}`, { replace: true })
  }

  const newNominationVersion = () => {
    navigate(`/nomination/${nominationId}/version/new`, { replace: true })
  }

  return {
    links: versionLinks,
    newNominationVersion,
    redirectToLatest,
  }
}

const buildNominationSummary = (nomination: NominationData): NominationSummary => {
  const { id, recordTime } = nomination

  return {
    title: id,
    recordTime: toString(recordTime),
  }
}

const useNominationDetailsViewModel = (nominationId: string, versionId: string | null): NominationDetailsViewModel => {
  const { fetching, nomination, versions } = useNominationModel(nominationId)
  const {
    nominationId: requestNominationId,
    nominationVersionId: requestNominationVersionId,
    setNativeNominationRequest,
    setExternalNominationRequest,
  } = useNominationRequestBridge()

  const [, navigate] = useLocation()
  const { lanes, goals, hubs, boats } = useSettingsContext()

  const latestVersionId = R.sortBy(R.prop('recordTime'), versions)[0]?.id
  const [currentVersionId, setCurrentVersionId] = useState(versionId)
  const [selectedStop, setSelectedStop] = useState<RiverLocationLite | null>(null)

  const selectedVersionData = useMemo(
    () => R.find(v => v.id === currentVersionId, versions),
    [currentVersionId, versions]
  )

  useEffect(() => {
    if (!currentVersionId) {
      setCurrentVersionId(latestVersionId)
    }
  }, [latestVersionId, currentVersionId])

  useEffect(() => {
    const shouldBeUpdated =
      selectedVersionData &&
      nominationId !== requestNominationId &&
      versionId &&
      versionId !== requestNominationVersionId
    if (shouldBeUpdated && selectedVersionData.type === OverviewNominationVersionType.Native) {
      setNativeNominationRequest(nominationId, versionId, selectedVersionData.nominationRequest)
    } else if (shouldBeUpdated && selectedVersionData.type === OverviewNominationVersionType.External) {
      // TODO: change it for the best possible nomination request substitution
      setExternalNominationRequest(nominationId, versionId, null)
    }
  }, [
    selectedVersionData,
    nominationId,
    versionId,
    requestNominationId,
    requestNominationVersionId,
    setNativeNominationRequest,
    setExternalNominationRequest,
  ])

  if (!nomination || fetching) {
    return { fetching: true }
  }

  const navigation: Navigation = buildNavigation(
    nominationId,
    versions,
    latestVersionId,
    navigate,
    currentVersionId,
    setCurrentVersionId
  )
  const nominationSummary: NominationSummary = buildNominationSummary(nomination)

  const selectedVersion = selectedVersionData
    ? buildSelectedVersion(selectedVersionData, lanes, goals, hubs, boats, selectedStop, setSelectedStop)
    : null

  return {
    fetching,
    navigation,
    nominationSummary,
    selectedVersion,
  }
}

export default useNominationDetailsViewModel
