import React, { type FC, useEffect } from 'react'

import classnames from 'classnames'
import * as R from 'ramda'
import { Link } from 'wouter'

import { PrimaryMenu } from '../../../components/Header/PrimaryMenu/PrimaryMenu'
import { NominatedBarge } from '../../../Domain/Nomination'
import { OverviewNominationVersionType } from '../../../generated/graphql'
import { Layout } from '../../../ui/Layout/Layout'
import { LoadingSpinner } from '../../../ui/Spinner/Spinner'

import BargeListBlock from './BargeListBlock'
import JourneyBlock from './JourneyBlock'
import NominationSummaryBlock from './NominationSummaryBlock'
import NominationVersionSummary from './NominationVersionSummary'
import styles from './styles.module.scss'
import useNominationDetailsViewModel, { getNominatedBargesForStop, isFetched, isFetching, type VersionLink } from './viewModel'

type NominationDetailsPageProps = {
  readonly id: string
  readonly version: string | null
}

const versionTypeClass = {
  [OverviewNominationVersionType.Native]: styles.native,
  [OverviewNominationVersionType.External]: styles.external,
}

const renderVersionLink = (version: VersionLink) => {
  const classes = [styles.versionLink, versionTypeClass[version.type], version.active ? styles.active : null]
  return (
    <Link className={classnames(classes)} to={version.path} key={version.id}>
      {version.recordTime}
    </Link>
  )
}

const NominationDetailsPage: FC<NominationDetailsPageProps> = ({ id, version }) => {
  const viewModel = useNominationDetailsViewModel(id, version)

  useEffect(() => {
    if (isFetched(viewModel) && !version) {
      viewModel.navigation.redirectToLatest()
    }

    if (isFetched(viewModel) && !viewModel.selectedVersion) {
      viewModel.navigation.redirectToLatest()
    }
  }, [viewModel, version])

  if (isFetching(viewModel)) {
    return <LoadingSpinner isFullScreen />
  }

  const { navigation, nominationSummary, selectedVersion } = viewModel
  const versionType = selectedVersion?.summary.type ?? OverviewNominationVersionType.Native

  const filteredNominatedBarges: NominatedBarge[] = selectedVersion
    ? getNominatedBargesForStop(
      selectedVersion.journey,
      selectedVersion.nominatedBarges,
      selectedVersion.journey.selectedStop
    )
    : []

  const versionSelectors = R.map(renderVersionLink, navigation.links)

  return (
    <Layout
      primaryMenu={({ isDrawerOpen, setIsDrawerOpen }) => (
        <PrimaryMenu isDrawerOpen={isDrawerOpen} setDrawerOpen={setIsDrawerOpen} />
      )}>
      <NominationSummaryBlock summary={nominationSummary} navigation={navigation} />
      <nav className={styles.versionNavigation}>{versionSelectors}</nav>
      {selectedVersion && (
        <section className={classnames([styles.version, versionTypeClass[versionType]])}>
          <NominationVersionSummary summary={selectedVersion.summary} />
          <JourneyBlock journey={selectedVersion.journey} />
          <BargeListBlock
            nominatedBarges={filteredNominatedBarges}
            bargeNamesCallback={selectedVersion.bargeNamesCallback}
            downloadNominationCsvCallback={selectedVersion.downloadNominationCsvCallback}
          />
        </section>
      )}
    </Layout>
  )
}

export default NominationDetailsPage
