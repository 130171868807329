import { type Context, createContext, type PropsWithChildren, useCallback, useContext, useReducer } from 'react'

import { OverviewNominationVersionType } from '../generated/graphql'

import type { VersionedNominationRequest } from '../models/models'

type NominationRequestBridgeState = {
  nominationId: string | null
  nominationVersionId: string | null
  nominationRequest: VersionedNominationRequest | null
  nominationVersionType: OverviewNominationVersionType | null
}

interface NominationRequestBridgeInterface extends NominationRequestBridgeState {
  setNativeNominationRequest(
    nominationId: string,
    nominationVersionId: string,
    nominationRequest: VersionedNominationRequest
  ): void
  setExternalNominationRequest(
    nominationId: string,
    nominationVersionId: string,
    nominationRequest: VersionedNominationRequest | null
  ): void
  clearNominationRequest(): void
}

const stub = (): never => {
  throw new Error('You forgot to wrap your component in <NominationRequestBridgeProvider>.')
}

const initialNominationRequestState: NominationRequestBridgeState = {
  nominationId: null,
  nominationVersionId: null,
  nominationRequest: null,
  nominationVersionType: null,
}

const initialState: NominationRequestBridgeInterface = {
  ...initialNominationRequestState,
  setNativeNominationRequest: stub,
  setExternalNominationRequest: stub,
  clearNominationRequest: stub,
}

const NominationRequestBridge: Context<NominationRequestBridgeInterface> =
  createContext<NominationRequestBridgeInterface>(initialState)

enum ActionTypes {
  SET_NATIVE_NOMINATION_REQUEST = 'SET_NATIVE_NOMINATION_REQUEST',
  SET_EXTERNAL_NOMINATION_REQUEST = 'SET_EXTERNAL_NOMINATION_REQUEST',
  CLEAR = 'CLEAR',
}

type ActionPayload = {
  nominationId: string
  nominationVersionId: string
  nominationRequest: VersionedNominationRequest
}
type Action =
  | {
      type: ActionTypes.SET_NATIVE_NOMINATION_REQUEST | ActionTypes.SET_EXTERNAL_NOMINATION_REQUEST
      payload: ActionPayload
    }
  | { type: ActionTypes.CLEAR }

const reducer = (state: NominationRequestBridgeState, action: Action) => {
  switch (action.type) {
    case ActionTypes.SET_NATIVE_NOMINATION_REQUEST:
      return {
        nominationId: action.payload.nominationId,
        nominationVersionId: action.payload.nominationVersionId,
        nominationRequest: action.payload.nominationRequest,
        nominationVersionType: OverviewNominationVersionType.Native,
      }
    case ActionTypes.SET_EXTERNAL_NOMINATION_REQUEST:
      return {
        nominationId: action.payload.nominationId,
        nominationVersionId: action.payload.nominationVersionId,
        nominationRequest: action.payload.nominationRequest,
        nominationVersionType: OverviewNominationVersionType.External,
      }
    case ActionTypes.CLEAR:
      return initialState
    default:
      return state
  }
}

const NominationRequestBridgeProvider = ({ children }: PropsWithChildren) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const setNativeNominationRequest = useCallback(
    (nominationId: string, nominationVersionId: string, nominationRequest: VersionedNominationRequest) => {
      dispatch({
        type: ActionTypes.SET_NATIVE_NOMINATION_REQUEST,
        payload: { nominationId, nominationVersionId, nominationRequest },
      })
    },
    [dispatch]
  )

  const setExternalNominationRequest = useCallback(
    (nominationId: string, nominationVersionId: string, nominationRequest: VersionedNominationRequest) => {
      dispatch({
        type: ActionTypes.SET_EXTERNAL_NOMINATION_REQUEST,
        payload: { nominationId, nominationVersionId, nominationRequest },
      })
    },
    [dispatch]
  )

  const clearNominationRequest = useCallback(() => {
    dispatch({ type: ActionTypes.CLEAR })
  }, [dispatch])

  const providerOps: NominationRequestBridgeInterface = {
    ...state,
    setNativeNominationRequest,
    setExternalNominationRequest,
    clearNominationRequest,
  }

  return <NominationRequestBridge.Provider value={providerOps}>{children}</NominationRequestBridge.Provider>
}

export default NominationRequestBridgeProvider

export const useNominationRequestBridge: () => NominationRequestBridgeInterface = () =>
  useContext(NominationRequestBridge)
