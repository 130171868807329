import React, { type FC } from 'react'

import Button from '../../../components/Button'
import { PrimaryMenu, PrimaryMenuRight } from '../../../components/Header/PrimaryMenu/PrimaryMenu'
import KVSelector from '../../../components/Header/Selector/KVSelector'
import { Layout } from '../../../ui/Layout/Layout'
import { LoadingSpinner } from '../../../ui/Spinner/Spinner'

import styles from './styles.module.scss'
import useNominationsViewModel, { type NominationCard, OwnershipOptions } from './viewModel'

const NominationTile: FC<NominationCard> = nomination => {
  return (
    <div className={styles.nomination} onClick={() => nomination.open()} key={nomination.id}>
      <div className={styles.title}>{nomination.title}</div>
      <div className={styles.details}>
        <div className={styles.general}>
          <span className={styles.label}>Creation date:</span>
          <span className={styles.value}>{nomination.recordTime}</span>
        </div>
        <div className={styles.tbo}>
          <span className={styles.label}>TBO Status:</span>
          <span className={styles.value}>...</span>
        </div>
      </div>
    </div>
  )
}

const NominationListPage = () => {
  const { fetching, newNomination, nominations, ownershipFilter, setOwnershipFilter } = useNominationsViewModel()

  let nominationSection
  if (fetching) {
    nominationSection = (
      <div className={styles.centeredSpinner}>
        <LoadingSpinner isFullScreen={false} />
      </div>
    )
  } else if (nominations.length === 0) {
    nominationSection = <div className={styles.emptyList}>No nominations found</div>
  } else {
    nominationSection = nominations.map(NominationTile)
  }

  return (
    <Layout
      primaryMenu={({ isDrawerOpen, setIsDrawerOpen }) => (
        <PrimaryMenu isDrawerOpen={isDrawerOpen} setDrawerOpen={setIsDrawerOpen} />
      )}>
      <div className={styles.container}>
        <div className={styles.section}>
          <header className={styles.header}>
            <div className={styles.newNominationHeader}>
              <h3>Nominations</h3>
              <PrimaryMenuRight>
                <div className={styles.primaryMenuItems}>
                  <KVSelector
                    label="Owner"
                    selected={ownershipFilter}
                    options={OwnershipOptions}
                    onChange={setOwnershipFilter}
                    tooltipText="Grouping table columns"
                  />
                  <Button onClick={newNomination} className={styles.button}>
                    New nomination
                  </Button>
                </div>
              </PrimaryMenuRight>
            </div>
          </header>
          <section className={styles.nominations}>{nominationSection}</section>
        </div>
      </div>
    </Layout>
  )
}

export default NominationListPage
