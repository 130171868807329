import React, { FC } from 'react'

import { HighlightOff } from '@mui/icons-material'
import classnames from 'classnames'

import ComboboxSelector from '../../../../components/Header/ComboboxSelector/ComboboxSelector'
import { GoalId } from '../../../../generated/graphql'
import { defaultNumberedTheme, NumberedInput } from '../../../../ui/NumberInput/NumberInput'
import { ErrorNotification } from '../../../Account/Form'

import styles from './styles.module.scss'

export const NumberInputSection: FC<{
    label: string;
    value: number;
    maxValue: number;
    onChange: (value: number) => void;
    warnings?: string;
    placeholder?: string;
    disabled?: boolean;
}> = ({ label, value, maxValue, onChange, warnings, placeholder, disabled }) => {


    const handleInputChange = (nr: number) => {
        if (nr >= 0 && nr <= maxValue) {
            onChange(nr)
        }
    }

    return (
        <section className={styles.section}>
            <span className={styles.label}>{label}</span>
            <NumberedInput
                min={0}
                max={maxValue}
                size={maxValue.toString().length}
                handleInput={handleInputChange}
                theme={defaultNumberedTheme}
                input={{
                    step: 1,
                    value,
                    placeholder,
                    disabled,
                }}
            />
            {warnings && <ErrorNotification message={warnings} />}
        </section>
    )
}

export const NumberInputWithReset: FC<{
    label: string
    value: number | null
    minValue: number
    maxValue: number
    onChange: (value: number | null) => void
    onClear: () => void
    placeholder?: string
    disabled?: boolean 
    warnings?: string
}> = ({ label, value, maxValue, onChange, onClear, placeholder, disabled, warnings }) => (
    <section className={styles.section}>
        <span className={styles.label}>{label}</span>
        <div className={styles.numberOfStringsWrapper}>
            <NumberedInput
                min={0}
                max={maxValue}
                size={2}
                placeholder={placeholder}
                handleInput={(val: number) => onChange(val)}
                theme={{ container: classnames(styles.inputContainer) }}
                input={{
                    step: 1,
                    value: value ?? '',
                }}
            />
            <HighlightOff
                className={classnames(styles.clear, { [styles.isDisabled]: disabled ?? value === 0 })}
                onClick={e => {
                    e.stopPropagation()
                    if (!(disabled ?? value === 0)) onClear() 
                }}
            />
        </div>
        {warnings && <ErrorNotification message={warnings} />}
    </section>
)


export const GoalSelection: FC<{
    goals: Record<GoalId, { label: string }>;
    selectedGoal: GoalId | null;
    onSelect: (goal: GoalId) => void;
    warnings?: string;
}> = ({ goals, selectedGoal, onSelect, warnings }) => {

    const items = Object.entries(goals).reduce((acc, [key, value]) => {
        acc[key as GoalId] = { label: value.label }
        return acc
    }, {} as Record<GoalId, { label: string }>)

    const displayValue = (itemId: string | undefined): string => {
        const goalId = itemId as GoalId
        return goalId && goalId in goals ? goals[goalId].label : 'Select a goal'
    }

    return (
        <section className={styles.section}>
            <div className={styles.goalBlock}>
                <div className={classnames(styles.label, styles.goals)}>
                    <span>Operational goal</span>
                </div>
                <ComboboxSelector
                    value={selectedGoal || ''}
                    handleChange={(goalId) => onSelect(goalId as GoalId)}
                    items={items}
                    displayValue={displayValue}
                    placeholder="Select a goal"
                    IconComponent={() => <span className={styles.icon}>🎯</span>}
                    isDisabled={false}
                />
                {warnings && <ErrorNotification message={warnings} />}
            </div>
        </section>
    )
}
